import React from 'react';

const Robotic = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="./images/roboticinside.jpg"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">

                              </h1>
                         </div>
                    </div>
               </div>
               <div className="p-6 bg-gray-100">
                    <div className="max-w-5xl mx-auto text-gray-800">
                         {/* Header */}
                         <h1 className="text-4xl font-bold text-blue-700 mb-4">Revolutionising the Future with Smart Robotics</h1>
                         <p className="text-lg mb-8">
                              Our smart robotics solutions are designed to transform industries, improve operational efficiency, and enhance productivity. Powered by cutting-edge artificial intelligence and advanced machine learning algorithms, our robots are capable of performing a wide range of tasks with precision and autonomy. With AI-driven capabilities, autonomous navigation, and real-time data integration, our robots are ready to take on the most challenging tasks, allowing businesses to stay ahead in a rapidly evolving technological landscape.
                         </p>

                         {/* Overview */}
                         <h2 className="text-3xl font-semibold text-blue-700 mt-8 mb-4">Overview</h2>
                         <p className="mb-6">
                              Smart robotics leverages artificial intelligence (AI), machine learning, and advanced sensors to create intelligent machines that can perform tasks autonomously, interact with their environment, and adapt to changing conditions. These robots are designed to work alongside humans, making them an ideal solution for industries looking to enhance operational efficiency, improve safety, and reduce costs.
                         </p>
                         <p className="mb-6">
                              Our robotic solutions are fully customizable to meet the unique needs of different sectors, including manufacturing, healthcare, logistics, retail, and more. From performing routine tasks like assembly line automation to assisting in complex surgeries, our robots are engineered to support and enhance human capabilities, ensuring higher performance and better outcomes.
                         </p>

                         {/* Key Features */}
                         <h2 className="text-3xl font-semibold text-blue-700 mt-8 mb-4">Key Features</h2>
                         <ul className="list-disc list-inside mb-8 space-y-4">
                              <li><strong>Autonomous Navigation:</strong> Equipped with sophisticated sensors, our robots navigate complex environments, providing seamless operation in both indoor and outdoor settings.</li>
                              <li><strong>Intelligent Automation:</strong> With deep learning capabilities, our robots autonomously analyze, learn, and adapt to surroundings, ideal for industries like manufacturing, logistics, and healthcare.</li>
                              <li><strong>Enhanced Efficiency:</strong> Designed to work alongside humans, our robots streamline processes, reduce errors, and minimize downtime.</li>
                              <li><strong>Real-time Data Integration:</strong> Fully integrated with cloud platforms, providing real-time data collection and insights for data-driven decision-making.</li>
                              <li><strong>Safety and Reliability:</strong> Equipped with advanced safety protocols, our robots ensure smooth interactions with humans and machinery, designed for durability in demanding environments.</li>
                         </ul>

                         {/* Services */}
                         <h2 className="text-3xl font-semibold text-blue-700 mt-8 mb-4">Our Smart Robotics Services Include:</h2>
                         <ul className="list-disc list-inside mb-8 space-y-4">
                              <li><strong>Custom Robotics Development:</strong> We design and build robots tailored to your business requirements, enhancing workflow and operations.</li>
                              <li><strong>Robotic System Integration:</strong> Our expert team ensures smooth integration of robotic systems with existing infrastructure.</li>
                              <li><strong>AI & Machine Learning Integration:</strong> Our robots continuously learn and adapt to new tasks, optimizing performance over time.</li>
                              <li><strong>Automation Consulting:</strong> Our consultants identify areas for impactful automation, streamlining processes and enhancing safety.</li>
                              <li><strong>Ongoing Maintenance & Support:</strong> We provide regular maintenance and support to keep robotic systems running optimally.</li>
                              <li><strong>Real-time Data Monitoring and Analytics:</strong> Access to detailed data insights and performance reports for continuous operational improvement.</li>
                              <li><strong>Training and Skill Development:</strong> Comprehensive training programs ensure your team is fully equipped to operate and collaborate with robotic systems.</li>
                         </ul>

                         {/* Why Choose Us */}
                         <h2 className="text-3xl font-semibold text-blue-700 mt-8 mb-4">Why Choose Our Smart Robotics Services?</h2>
                         <ul className="list-disc list-inside mb-8 space-y-4">
                              <li><strong>End-to-End Solutions:</strong> From consultation and design to ongoing support, we provide tailored services for every aspect of robotics implementation.</li>
                              <li><strong>Increased Efficiency:</strong> Automation reduces manual labor, increases speed, and minimizes errors, significantly improving productivity.</li>
                              <li><strong>Enhanced Safety:</strong> Our robots handle dangerous or repetitive tasks, improving workplace safety and ensuring quality.</li>
                              <li><strong>Scalable and Flexible:</strong> Our services grow with your business, providing scalable solutions to meet evolving needs.</li>
                         </ul>

                         {/* Call to Action */}
                         <h2 className="text-3xl font-semibold text-blue-700 mt-8 mb-4">Take the Next Step in Automation</h2>
                         <p className="mb-8">
                              Ready to embrace the future of automation? Our Smart Robotics Services can help your business stay competitive, innovative, and efficient. Contact us today to discuss how our robotic solutions can be integrated into your operations and take your business to the next level.
                         </p>
                    </div>
               </div>
          </>
     );
};

export default Robotic;
