import React from 'react';

const Blockchain = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="./images/blockchaininside.jpg"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">

                              </h1>
                         </div>
                    </div>
               </div>
               <div className="container mx-auto px-56 py-10 space-y-10">
                    <section className="space-y-4 text-center">
                         <h1 className="text-4xl font-bold text-gray-800">At iJentech</h1>
                         <p className="text-lg text-gray-600">
                              We specialize in driving the transformation of businesses through trusted, blockchain-powered networks that bring long-term value to all stakeholders. Our approach focuses on crafting meaningful commercial and incentive models that foster collaboration, accountability, and mutual benefit. Whether it's enhancing supply chain transparency, ensuring data integrity, or developing sustainable business practices, we are committed to creating reliable, scalable ecosystems tailored to your industry needs.
                         </p>
                    </section>

                    <section className="space-y-8">
                         <h2 className="text-3xl font-semibold text-gray-800">Blockchain Services</h2>

                         {/* Blockchain Strategy and Consulting */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Blockchain Strategy and Consulting</h3>
                              <p className="text-gray-600">
                                   Our blockchain consulting services start with understanding your business needs, industry landscape, and the potential of blockchain to address specific challenges.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Use case identification and feasibility analysis</li>
                                   <li>Blockchain readiness assessment</li>
                                   <li>Strategic roadmap development and business modeling</li>
                              </ul>
                         </div>

                         {/* Custom Blockchain Development */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Custom Blockchain Development</h3>
                              <p className="text-gray-600">
                                   With a focus on reliability and scalability, our blockchain development services allow businesses to build tailored solutions that fit their requirements.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>End-to-end blockchain application development</li>
                                   <li>Smart contract creation and deployment</li>
                                   <li>Integration with existing business systems</li>
                                   <li>High-security protocols and privacy measures</li>
                              </ul>
                         </div>

                         {/* Enterprise Blockchain Network Design and Implementation */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Enterprise Blockchain Network Design and Implementation</h3>
                              <p className="text-gray-600">
                                   We excel in designing blockchain-powered networks that connect all players in your ecosystem, from suppliers to customers.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Network architecture design and implementation</li>
                                   <li>Ecosystem and stakeholder mapping</li>
                                   <li>Incentive model design to drive engagement and value creation</li>
                                   <li>Cross-industry blockchain solutions for finance, healthcare, logistics, and more</li>
                              </ul>
                         </div>

                         {/* Smart Contracts and Automation */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Smart Contracts and Automation</h3>
                              <p className="text-gray-600">
                                   We create secure, efficient, and tamper-proof smart contracts that automate repetitive processes, enforce transparency, and reduce human errors.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Custom smart contract design and development</li>
                                   <li>Thorough testing and security auditing</li>
                                   <li>Automation of processes such as payment settlements and asset tracking</li>
                              </ul>
                         </div>

                         {/* Blockchain Integration and Interoperability Solutions */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Blockchain Integration and Interoperability Solutions</h3>
                              <p className="text-gray-600">
                                   Our integration services are designed to ensure seamless compatibility between blockchain solutions and your current technology environment.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>API development for seamless integration</li>
                                   <li>Cross-platform and cross-chain interoperability</li>
                                   <li>Secure data bridges between blockchain and legacy systems</li>
                              </ul>
                         </div>

                         {/* Decentralized Finance (DeFi) and Tokenization */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Decentralized Finance (DeFi) and Tokenization</h3>
                              <p className="text-gray-600">
                                   Tokenization enables businesses to convert real-world assets into digital tokens, facilitating liquidity and secure transactions on the blockchain.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Asset tokenization for real estate, IP, and more</li>
                                   <li>DeFi application development for lending, staking, and liquidity pools</li>
                                   <li>Secure wallet and transaction solutions</li>
                              </ul>
                         </div>

                         {/* Blockchain Security and Compliance */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Blockchain Security and Compliance</h3>
                              <p className="text-gray-600">
                                   Security and compliance are at the heart of every blockchain solution we create.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Security audits and vulnerability assessments</li>
                                   <li>Compliance with GDPR, CCPA, and other regulations</li>
                                   <li>Secure cryptographic key management</li>
                              </ul>
                         </div>

                         {/* Ongoing Support and Maintenance */}
                         <div className="space-y-4">
                              <h3 className="text-2xl font-semibold text-blue-700">Ongoing Support and Maintenance</h3>
                              <p className="text-gray-600">
                                   Our support and maintenance services ensure that your blockchain solutions remain functional, efficient, and adaptable.
                              </p>
                              <ul className="list-disc list-inside text-gray-600">
                                   <li>Regular system updates and bug fixes</li>
                                   <li>Performance monitoring and optimization</li>
                                   <li>24/7 technical support</li>
                              </ul>
                         </div>
                    </section>

                    <section className="text-center py-10">
                         <h2 className="text-2xl font-semibold text-gray-800">Why Choose iJentech for Blockchain Solutions?</h2>
                         <p className="text-gray-600">
                              Partner with a team committed to building blockchain solutions that are secure, efficient, and beneficial for all participants. Our approach prioritizes industry expertise, stakeholder-driven models, sustainability focus, and scalable solutions.
                         </p>
                    </section>
               </div>
          </>
     );
};

export default Blockchain;
