import React, { useEffect, useState, useRef } from 'react';

// Component for a single roadmap step
const RoadmapStep = ({ title, description, index }) => {
     const [isVisible, setIsVisible] = useState(false);
     const stepRef = useRef(null);

     useEffect(() => {
          const observer = new IntersectionObserver(
               (entries) => {
                    entries.forEach((entry) => {
                         if (entry.isIntersecting) {
                              setIsVisible(true);
                              observer.unobserve(entry.target); // Stop observing once it's visible
                         }
                    });
               },
               { threshold: 0.2 }
          );

          if (stepRef.current) {
               observer.observe(stepRef.current);
          }

          return () => {
               if (stepRef.current) observer.unobserve(stepRef.current);
          };
     }, []);

     return (
          <div
               ref={stepRef}
               className={`relative flex items-center w-full md:w-1/2 mx-auto mb-8 transform transition-all duration-700 ease-in-out ${isVisible
                    ? 'opacity-100 translate-y-0'
                    : `opacity-0 ${index % 2 === 0 ? 'translate-y-10' : 'translate-y-10'}`}`}
          >
               {/* Circle Icon for Roadmap Step */}
               <div className="w-4 h-4 md:w-6 md:h-6 bg-blue-600 rounded-full shadow-md z-10"></div>
               {/* Roadmap Step Content */}
               <div className="ml-2 md:ml-4">
                    <h4 className="text-base md:text-lg font-semibold">{title}</h4>
                    <p className="text-gray-600 text-sm md:text-base">{description}</p>
               </div>
          </div>
     );
};

const IoTDevelopmentServices = () => {
     const roadmapSteps = [
          { title: 'GPS Platforms', description: 'Accurate location tracking with GPS.' },
          { title: 'Microservices Architecture', description: 'Scalable Python microservices.' },
          { title: 'Camera Detection', description: 'AI-powered camera solutions.' },
          { title: 'Data Analysis', description: 'Actionable insights through data.' },
     ];

     return (
          <>
               {/* Hero Section */}
               <div className="relative h-screen overflow-hidden">
                    <img
                         src="https://img.freepik.com/premium-photo/illustration-smart-home-neighborhood-with-interconnected-devices_14117-1075707.jpg?w=900"
                         alt="Background"
                         className="w-full h-full object-cover sm:object-fill"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-8">
                         <div className="text-left text-white">
                              <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-4 sm:mb-6 ml-4 sm:ml-8 sm:mr-32">
                                   It's the beginning of <span className="underline decoration-red-500">machines</span> taking over the world.
                              </h1>
                         </div>
                    </div>
               </div>

               {/* Roadmap Section */}
               <div className="max-w-7xl p-4 sm:p-6 bg-gray-100">
                    {/* Heading */}
                    <h2 className="text-2xl sm:text-4xl font-bold text-center mb-4">IoT Development Services</h2>

                    {/* Subheading */}
                    <h3 className="text-xl sm:text-2xl text-center mb-6">Innovative Solutions for a Connected World</h3>

                    {/* Roadmap Animation */}
                    <div className="relative">
                         {/* Vertical Line for Roadmap */}
                         <div className="absolute w-1 bg-blue-300 h-full top-0 left-1/2 transform -translate-x-1/2 hidden lg:block "></div>

                         {/* Roadmap Steps */}
                         <div className="flex flex-col space-y-8 md:space-y-0"> {/* Ensures spacing between steps on small devices */}
                              {roadmapSteps.map((step, index) => (
                                   <RoadmapStep
                                        key={index}
                                        title={step.title}
                                        description={step.description}
                                        index={index}
                                   />
                              ))}
                         </div>
                    </div>
                    {/* Advantages Section */}
                    <div className="mt-12">
                         <h3 className="text-2xl sm:text-3xl font-bold text-center mb-4">Advantages of Our Services</h3>
                         <ul className="list-disc list-inside space-y-2 text-center text-sm sm:text-base">
                              <li>Real-time Monitoring and Control</li>
                              <li>Scalable and Flexible Solutions</li>
                              <li>Enhanced Data Security</li>
                              <li>Cost-effective Implementations</li>
                         </ul>
                    </div>
               </div>
          </>
     );
};

export default IoTDevelopmentServices;
