import React from "react";
import { Link } from "react-router-dom";

const About = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="https://t4.ftcdn.net/jpg/05/08/77/97/360_F_508779720_mevGw0UiCDurA6A195ayIk5sxaGFwuEu.jpg" // Replace with your image URL
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-50 px-8">
                         <h1 className="text-4xl sm:text-6xl md:text-8xl font-bold text-center px-4 animate-fade-in">
                              Empowering Global Growth Through Innovation
                         </h1>
                         <style jsx>{`
      h1 {
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
        letter-spacing: 0.1em;
        animation: fadeIn 3s ease-in-out;
      }

      @keyframes fadeIn {
        from { opacity: 0; transform: translateY(20px); }
        to { opacity: 1; transform: translateY(0); }
      }
    `}</style>
                    </div>

                    {/* Floating Colorful Bubbles */}
                    <div className="absolute w-full h-full top-0 left-0 overflow-hidden">
                         {Array.from({ length: 12 }).map((_, index) => (
                              <div
                                   key={index}
                                   className={`absolute rounded-full animate-floating`}
                                   style={{
                                        backgroundColor: `hsl(${Math.random() * 360}, 80%, 60%)`, // Random color for each bubble
                                        width: `${Math.random() * 50 + 30}px`, // Size between 30px and 80px
                                        height: `${Math.random() * 50 + 30}px`,
                                        left: `${Math.random() * 100}%`, // Random horizontal position
                                        top: `${Math.random() * 100}%`, // Random vertical position
                                        animationDelay: `${Math.random() * 5}s`, // Random delay for each bubble's animation
                                        animationDuration: `${Math.random() * 6 + 3}s`, // Random duration between 3s and 9s
                                   }}
                              />
                         ))}
                    </div>

                    <style jsx>{`
                         @keyframes floating {
                              0% {
                              transform: translateY(0px) scale(1);
                              }
                              50% {
                              transform: translateY(-50px) scale(1.1);
                              }
                              100% {
                              transform: translateY(0px) scale(1);
                              }
                         }

                         .animate-floating {
                              animation: floating infinite ease-in-out;
                         }
                         `}</style>
               </div>

               <div className='bg-gray-100'>
                    <section className="py-12 px-4 max-w-6xl mx-auto">
                         <div className="flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 md:p-12">
                              <div className="mb-6 md:mb-0 md:w-1/2">
                                   <h2 className="text-3xl font-semibold mb-4 text-red-600">Our Mission</h2>
                                   <p className="text-gray-700 leading-relaxed px-2">
                                        At iJENTECH, our mission is to empower businesses through innovative technology solutions that enhance productivity and drive success. We are dedicated to delivering exceptional services and creating lasting partnerships that foster growth and transformation.
                                   </p>
                              </div>
                              <div className="md:w-1/2">
                                   <h2 className="text-3xl font-semibold mb-4 text-red-600">Our Vision</h2>
                                   <p className="text-gray-700 leading-relaxed">
                                        Our vision is to be a global leader in technology solutions, recognized for our commitment to excellence and customer satisfaction. We aspire to create a future where technology seamlessly integrates into everyday life, enabling individuals and organizations to reach their full potential.
                                   </p>
                              </div>
                         </div>
                    </section>
               </div>

               <div className="bg-gray-100 md:mb-0 sm:mb-0 mb-28">
                    {/* Values Section */}
                    <section className="py-12 px-4 max-w-6xl mx-auto">
                         <h2 className="text-4xl font-bold text-center mb-8">Our Values</h2>
                         <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Innovation</h3>
                                   <p className="text-gray-600">We thrive on creativity and new ideas, constantly pushing boundaries to bring cutting-edge solutions.</p>
                              </li>
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Integrity</h3>
                                   <p className="text-gray-600">We uphold the highest standards of honesty and transparency in all our interactions.</p>
                              </li>
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Collaboration</h3>
                                   <p className="text-gray-600">We believe in teamwork, leveraging our collective expertise to achieve greater results.</p>
                              </li>
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Customer Centricity</h3>
                                   <p className="text-gray-600">Our clients are at the heart of everything we do, and we strive to exceed their expectations.</p>
                              </li>
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Excellence</h3>
                                   <p className="text-gray-600">We are committed to delivering top-quality services and solutions that drive success.</p>
                              </li>
                              <li className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105">
                                   <h3 className="text-xl font-semibold">Sustainability</h3>
                                   <p className="text-gray-600">We prioritize sustainable practices in our operations and advocate for a greener future.</p>
                              </li>
                         </ul>
                    </section>
               </div>

               <div className="relative h-screen flex items-center justify-center bg-gray-100 py-10">
                    {/* Our Team Section */}
                    <div className="container mx-auto px-6 py-12 flex flex-col md:flex-row items-center space-y-12 md:space-y-0">

                         {/* Left Side - Team Content */}
                         <div className="md:w-1/2 space-y-6">
                              <h2 className="text-4xl sm:text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500">
                                   Meet Our Amazing Team
                              </h2>
                              <p className="text-lg text-gray-700">
                                   At <span className="font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500">iJENTECH</span>, we believe in fostering innovation, collaboration, and growth. Our team is composed of talented individuals who are passionate about creating cutting-edge technology solutions and driving global success. Together, we push boundaries, inspire creativity, and deliver excellence.
                              </p>
                              <p className="text-lg text-gray-700">
                                   With diverse backgrounds, skills, and expertise, each member contributes uniquely to our collective success. We work hard, we innovate, and we grow as a team!
                              </p>
                              <Link to='/career'><button className="mt-4 px-6 py-3 bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold rounded-md shadow-lg hover:from-purple-600 hover:to-blue-500 transition-all transform hover:scale-105">
                                   Join Our Team
                              </button></Link>
                         </div>

                         {/* Right Side - Team Photo Slider */}
                         <div className="w-full md:w-1/2 relative overflow-hidden">
                              <div className="w-full h-64 sm:h-80 md:h-96 relative">
                                   <div className="absolute inset-0 flex space-x-4 items-center justify-center animate-marquee sm:mb-0 mb-20 w-full">
                                        {/* Team Members (Photos Tilted) */}
                                        {['./images/ssl.jpg', './images/ssl.jpg', './images/ssl.jpg', './images/amit.jpg', './images/ssl.jpg',].map((image, index) => (
                                             <div key={index} className="relative transform rotate-6 hover:rotate-0 transition-transform duration-500">
                                                  <img
                                                       src={`${process.env.PUBLIC_URL}${image}`} // Replace with actual image URLs
                                                       alt={`Team Member ${index + 1}`}
                                                       className="w-28 h-36 sm:w-36 sm:h-48 md:w-40 md:h-52  object-cover rounded-lg shadow-lg"
                                                  />
                                             </div>
                                        ))}
                                   </div>
                              </div>
                         </div>                        
                    </div>
                    <style jsx>{`
                    @keyframes marquee {
                    0% { transform: translateX(100%); }
                    100% { transform: translateX(-100%); }
                    }
                    .animate-marquee {
                    animation: marquee 25s linear infinite;
                    }
                    `}</style>
               </div>
          </>
     );
}
export default About;