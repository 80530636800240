import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'

const reviews = [
  {
    name: 'Robert Statham',
    image: 'https://via.placeholder.com/100', // Replace with actual image URL
    rating: 5,
    text: "Our experience with iJentech Technology has been exceptional. From the beginning, their team demonstrated a deep understanding of our business needs and provided us with innovative solutions tailored to our specific requirements. Whether it was managing our IT infrastructure, developing custom software, or ensuring robust cybersecurity measures, it's consistently exceeded our expectations."
  },
  {
    name: 'Emily Parker',
    image: 'https://via.placeholder.com/100', // Replace with actual image URL
    rating: 4,
    text: 'We highly recommend iJentech Technology to any organization seeking a reliable, forward-thinking IT partner. Their technical skills, commitment to quality, and focus on client satisfaction truly set them apart in the industry.'
  },
  {
    name: 'John Doe',
    image: 'https://via.placeholder.com/100', // Replace with actual image URL
    rating: 5,
    text: 'Amazing work! The team was professional and delivered excellent results.'
  },
  {
    name: 'Sarah Wilson',
    image: 'https://via.placeholder.com/100', // Replace with actual image URL
    rating: 4,
    text: 'Great experience working with the team. Timely delivery and high-quality results.'
  }
]

const ReviewCards = () => {
  return (
    <div className='bg-gray-100'>
      <div className='max-w-4xl mx-auto text-center py-12 px-4'>
        {/* <h2 className="text-3xl font-bold bg-white mb-2">Client Testimonials</h2> */}
        <h2 className='text-3xl font-semibold mb-4 bg-white p-2 shadow-md rounded-md'>
          Clients Feedback
        </h2>
        <p className='text-gray-500 mb-8'>
          See what satisfied clients are saying about us.
        </p>

        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000 }}
          loop={true}
          spaceBetween={30}
          slidesPerView={1}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index}>
              <div className='flex flex-col items-center mb-10'>
                <img
                  src={review.image}
                  alt={review.name}
                  className='w-24 h-24 rounded-full mb-4'
                />
                <h3 className='text-lg font-semibold'>{review.name}</h3>
                <div className='flex justify-center items-center mt-2 mb-4'>
                  {Array.from({ length: review.rating }).map((_, i) => (
                    <span key={i} className='text-purple-500 text-lg'>
                      ★
                    </span>
                  ))}
                </div>
                <p className='text-gray-600 max-w-2xl text-center text-sm sm:text-base'>
                  {review.text}
                </p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default ReviewCards
