import React from "react";

const HomeCard = ({ event }) => {
     return (
          <div className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition duration-300 ease-in-out">
               <img
                    className="w-full h-48 object-cover"
                    src={event.image}
                    alt={event.title}
               />
               <div className="p-4">
                    <h2 className="text-xl font-semibold mb-2">{event.title}</h2>
                    <p className="text-gray-700 mb-4">{event.description}</p>
                    <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white px-4 py-2 rounded-lg  transition">
                         view
                    </button>
               </div>
          </div>
     );
};

export default HomeCard;
