import React from 'react';

const SEOPage = () => {
     return (
          <>
               {/* Hero Section */}
               <div className="relative h-screen overflow-hidden">
                    <img
                         src="https://webflare.in/wp-content/uploads/2021/09/SEO-Marketing-Banner.jpg"
                         alt="Background"
                         className="w-full h-full object-fill sm:object-fill"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-8">
                         <div className="text-left text-white">
                              <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-4 sm:mb-6 ml-4 sm:ml-8 sm:mr-32">
                              Find Success in Every <span className="underline decoration-red-500">Search</span> .
                              </h1>
                         </div>
                    </div>
               </div>
               <div className="font-lato bg-gray-50 text-gray-800">
                    {/* Hero Section */}
                    <section className="text-black p-8 md:p-16">
                         <div className="container mx-auto text-center">
                              <h1 className="text-4xl md:text-5xl font-bold mb-4">SEO Services That Drive Results</h1>
                              <p className="text-lg md:text-xl mb-6">
                                   Unlock the power of SEO and elevate your website’s visibility with our expert services.
                              </p>
                         </div>
                    </section>

                    {/* Why SEO Section */}
                    <section className="container mx-auto my-3 px-8">
                         <h2 className="text-3xl font-bold text-center mb-10">Why SEO?</h2>
                         <div className="text-lg text-center">
                              <p className="mb-6">
                                   SEO is the foundation of your online presence. It enhances your website’s ranking on search engines, helping you reach your target audience and drive organic traffic.
                              </p>
                              <ul className="list-disc mx-auto text-left max-w-xl">
                                   <li>Increase Organic Traffic</li>
                                   <li>Enhance Website Visibility</li>
                                   <li>Build Brand Credibility</li>
                                   <li>Generate Quality Leads</li>
                                   <li>Stay Ahead of Competitors</li>
                              </ul>
                         </div>
                    </section>

                    {/* Our SEO Services Section */}
                    <section className="bg-gray-50 py-12">
                         <div className="container mx-auto text-center px-8">
                              <h2 className="text-3xl font-bold mb-10">Our SEO Services</h2>
                              <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                                   {/* Service 1 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">On-Page SEO</h3>
                                        <p className="mb-4">
                                             Optimize your website’s content, structure, and code to improve search engine rankings and user experience.
                                        </p>
                                   </div>
                                   {/* Service 2 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">Off-Page SEO</h3>
                                        <p className="mb-4">
                                             Build high-quality backlinks and improve your website's authority and credibility in the eyes of search engines.
                                        </p>
                                   </div>
                                   {/* Service 3 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">Technical SEO</h3>
                                        <p className="mb-4">
                                             Ensure that your website is properly indexed, fast, and secure to provide the best possible user experience.
                                        </p>
                                   </div>
                                   {/* Service 4 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">Local SEO</h3>
                                        <p className="mb-4">
                                             Optimize your online presence for local searches and make your business stand out in your community.
                                        </p>
                                   </div>
                                   {/* Service 5 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">SEO Audits</h3>
                                        <p className="mb-4">
                                             Conduct thorough audits of your website to identify areas for improvement and create a strategic SEO plan.
                                        </p>
                                   </div>
                                   {/* Service 6 */}
                                   <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                        <h3 className="text-2xl font-semibold mb-3">Content Strategy</h3>
                                        <p className="mb-4">
                                             Develop a content strategy that drives organic traffic, engages users, and aligns with your business goals.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </section>
               </div>
          </>
     );
};

export default SEOPage;
