import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

const servicesData = [
     {
          title: 'Web Development',
          description:
               'We provide top-notch web development services, specializing in modern, responsive, and SEO-friendly websites.',
          details:
               'Our expertise covers front-end technologies like React, Angular, and Vue.js, as well as back-end frameworks such as Node.js, Laravel, and Django. We ensure scalability, security, and performance.',
          image: 'https://miro.medium.com/v2/resize:fit:1200/0*M4bxiCIjcTK-2Xr6.jpeg',
          url: '/services/web-development',
     },
     {
          title: 'Mobile App Development',
          description:
               'We create high-quality mobile applications for iOS and Android platforms with a focus on performance and user experience.',
          details:
               'Our team develops native and cross-platform mobile apps using Swift, Kotlin, React Native, and Flutter. We bring innovative ideas to life, ensuring smooth, high-performance applications.',
          image: 'https://riseuplabs.com/wp-content/uploads/2021/07/mobile-application-development-guidelines-riseuplabs.jpg',
          url: '/services/mobileApp-development',
     },
     {
          title: 'IOT',
          description:
               'The Internet of Things (IoT) is a network of physical objects that are connected to the internet and can collect and share data.',
          details:
               'Our expertise covers The Internet of Things (IoT) of network is physical objects that can connect and share data over the internet. IoT devices are embedded with sensors, software, and other technologies, and can include anything with a unique identifier (UID) assigned to a sensor',
          image: 'https://img.freepik.com/free-vector/internet-things-background-with-hexagons_23-2147633063.jpg?w=740&t=st=1728045284~exp=1728045884~hmac=da30184be7c886c399cf526252642d2bbbc37b3dc1fea1d57f7e3b41be6224d2',
          url: '/services/iot-development',
     },
     {
          title: 'Digital Marketing',
          description:
               'Digital marketing is a strategic approach to promoting products, services, or brands through online channels to reach a broader audience, build relationships, and drive conversions.',
          details:
               'Our expertise covers Reach specific audience segments based on demographics, behaviors, and location. Many tactics, like social media, are more affordable than traditional advertising.',
          image: 'https://vinuni.edu.vn/wp-content/uploads/2024/05/digital-marketing-can-hoc-nhung-gi-co-hoi-viec-lam-cua-nganh-ra-sao-anh-1-1.jpg',
          url: '/services/digital-marketing',
     },
     {
          title: 'SEO Management',
          description:
               'SEO marketing is a digital marketing strategy focused on improving a website’s visibility and ranking on search engine results pages (SERPs).',
          details:
               'Our expertise covers Optimizing GMB listings with accurate business information, reviews, and photos to appear in local searches.',
          image: 'https://essinstitute.in/wp-content/uploads/2024/07/what-is-seo-1024x525.webp',
          url: '/services/seo-management',
     },
     {
          title: 'Other Services',
          description:
               'We offer a variety of other technology services to meet the diverse needs of our clients.',
          details:
               'In addition to web and mobile app development, we provide cloud solutions, API integrations, and IT consulting services, helping your business stay competitive and efficient.',
          image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvfuRS3IFyMaFEWgIoye4s_yBY4llKJlHy3w&s',
          url: '/services/other-development',
     },

];

const ServiceCard = ({ title, description, details, image, url }) => {
     return (
          <motion.div
               initial={{ opacity: 0, scale: 0.9 }}
               animate={{ opacity: 1, scale: 1 }}
               transition={{ duration: 0.5 }}
               className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 ease-in-out"
          >
               <motion.div whileHover={{ scale: 1.05 }} className="relative">
                    <img src={image} alt={title} className="w-full h-48 sm:h-64 object-cover" />
               </motion.div>
               <div className="p-4 sm:p-6">
                    <h3 className="text-xl sm:text-2xl font-bold mb-2">{title}</h3>
                    <p className="text-sm sm:text-base text-gray-600 mb-2">{description}</p>
                    <p className="text-sm pb-2 text-gray-500">{details}</p>
                    {/* <link rel="noopener noreferrer" to="{url}"  className="inline-block px-4 py-2 bg-blue-600 text-white font-bold rounded-lg hover:bg-blue-700 transition-colors duration-300">Learn More</link> */}
                    <a
                         href={url}
                         rel="noopener noreferrer"
                         className="inline-block px-4 py-2 bg-gradient-to-r from-blue-500 to-teal-400 text-white font-bold rounded-lg transition-colors duration-300"
                    >
                         Learn More
                    </a>
               </div>
          </motion.div>
     );
};

const ServicesPage = () => {
     const [visible, setVisible] = useState(false);
     const servicesRef = useRef(null);

     useEffect(() => {
          const observer = new IntersectionObserver(
               (entries) => {
                    entries.forEach((entry) => {
                         if (entry.isIntersecting) {
                              setVisible(true);
                         } else {
                              setVisible(false); // Reset visibility when not in view
                         }
                    });
               },
               { threshold: 0.2 } // Trigger when 40% of the section is visible
          );

          if (servicesRef.current) {
               observer.observe(servicesRef.current);
          }

          return () => {
               if (servicesRef.current) {
                    observer.unobserve(servicesRef.current);
               }
          };
     }, []);

     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">
                                   Unlocking Possibilities with{' '}
                                   <span className="underline decoration-red-500">
                                        Cutting-Edge Solutions!
                                   </span>
                              </h1>
                         </div>
                    </div>
               </div>

               {/* Services Section */}
               <div className="min-h-screen bg-gray-100 py-6 sm:py-12 px-10" ref={servicesRef}>
                    <div className="max-w-6xl mx-auto text-center mb-8 sm:mb-16">
                         <motion.h2
                              initial={{ opacity: 0, y: -50 }}
                              animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: -50 }}
                              transition={{ duration: 0.6 }}
                              className="text-2xl sm:text-4xl font-bold text-gray-800"
                         >
                              Our Services
                         </motion.h2>
                         <p className="text-sm sm:text-lg text-gray-600 mt-4">
                              We provide a wide range of high-quality services at iJENTECH.
                         </p>
                    </div>

                    {/* Service Cards */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8">
                         {servicesData.map((service, index) => (
                              <motion.div
                                   key={index}
                                   initial={{ opacity: 0, y: 50 }}
                                   animate={visible ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
                                   transition={{ duration: 0.5, delay: index * 0.4 }}
                                   className="h-full flex"
                              >
                                   <ServiceCard
                                        title={service.title}
                                        description={service.description}
                                        details={service.details}
                                        image={service.image}
                                        url={service.url}
                                        className="flex flex-col h-full"
                                   />
                              </motion.div>
                         ))}
                    </div>
               </div>
          </>
     );
};

export default ServicesPage;
