import React, { useEffect, useRef, useState } from "react";

const MobileAppDevelopment = () => {
     const sectionRef = useRef(null);
     const [inView, setInView] = useState(false);

     useEffect(() => {
          const observer = new IntersectionObserver(
               (entries) => {
                    entries.forEach((entry) => {
                         if (entry.isIntersecting) {
                              setInView(true);
                              observer.disconnect(); // Disconnect after animating to prevent it from resetting
                         }
                    });
               },
               { threshold: 0.1 }
          );

          if (sectionRef.current) {
               observer.observe(sectionRef.current);
          }

          return () => {
               if (sectionRef.current) {
                    observer.unobserve(sectionRef.current);
               }
          };
     }, []);

     return (
          <>


               <div className="relative h-screen overflow-hidden">

                    <img
                         src="https://img.freepik.com/premium-photo/key-tools-mobile-app-development_1186366-161587.jpg?w=900"
                         alt="Background"
                         className="w-full h-full sm:object-fill object-cover"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">
                                   Unlock endless possibilities right from your  <span> </span>
                                   <span className="underline decoration-red-500">
                                        fingertips.
                                   </span>
                              </h1>
                         </div>
                    </div>
               </div>
               <section ref={sectionRef} className="py-8 sm:py-12 bg-gray-100">
                    {/* Heading */}
                    <div className={`text-center mb-8 sm:mb-10 ${inView ? "animate-fadeInUp" : "opacity-0"}`}>
                         <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-2">
                              Mobile App Development
                         </h1>
                         <p className="text-base sm:text-lg text-gray-600">
                              Building powerful, intuitive, and efficient mobile solutions.
                         </p>
                    </div>

                    {/* Roadmap & Advantages */}
                    <div className="container mx-auto px-4 sm:px-6 md:px-12">
                         <div className="grid grid-cols-1 md:grid-cols-2 gap-8 sm:gap-12">
                              {/* Roadmap/Timeline */}
                              <div className="relative">
                                   {/* Vertical line in the center */}
                                   <div className="absolute w-1 h-full bg-blue-500 left-1/2 transform -translate-x-1/2 hidden md:block"></div>

                                   {/* Roadmap Item 1 */}
                                   <div className={`mb-8 flex items-center w-full ${inView ? "animate-slideInRight" : "opacity-0"}`}>
                                        <div className="w-full md:w-1/2 text-right pr-4">
                                             <h3 className="text-lg sm:text-xl font-semibold">Cross-Platform Solutions</h3>
                                             <p className="text-sm sm:text-base text-gray-600">Delivering apps that work seamlessly on Android and iOS.</p>
                                        </div>
                                        <div className="relative w-8 h-8 bg-blue-500 rounded-full flex justify-center items-center text-white font-bold mx-auto md:mx-0">
                                             1
                                        </div>
                                   </div>

                                   {/* Roadmap Item 2 */}
                                   <div className={`mb-8 flex items-center w-full ${inView ? "animate-slideInLeft delay-200" : "opacity-0"}`}>
                                        <div className="relative w-8 h-8 bg-blue-500 rounded-full flex justify-center items-center text-white font-bold mx-auto md:mx-0">
                                             2
                                        </div>
                                        <div className="w-full md:w-1/2 text-left pl-4">
                                             <h3 className="text-lg sm:text-xl font-semibold">User-Centric Design</h3>
                                             <p className="text-sm sm:text-base text-gray-600">Intuitive and engaging interfaces designed with users in mind.</p>
                                        </div>
                                   </div>

                                   {/* Roadmap Item 3 */}
                                   <div className={`mb-8 flex items-center w-full ${inView ? "animate-slideInRight delay-300" : "opacity-0"}`}>
                                        <div className="w-full md:w-1/2 text-right pr-4">
                                             <h3 className="text-lg sm:text-xl font-semibold">Performance Optimization</h3>
                                             <p className="text-sm sm:text-base text-gray-600">Ensuring fast load times and smooth user experiences.</p>
                                        </div>
                                        <div className="relative w-8 h-8 bg-blue-500 rounded-full flex justify-center items-center text-white font-bold mx-auto md:mx-0">
                                             3
                                        </div>
                                   </div>

                                   {/* Roadmap Item 4 */}
                                   <div className={`flex items-center w-full ${inView ? "animate-slideInLeft delay-400" : "opacity-0"}`}>
                                        <div className="relative w-8 h-8 bg-blue-500 rounded-full flex justify-center items-center text-white font-bold mx-auto md:mx-0">
                                             4
                                        </div>
                                        <div className="w-full md:w-1/2 text-left pl-4">
                                             <h3 className="text-lg sm:text-xl font-semibold">Scalability & Security</h3>
                                             <p className="text-sm sm:text-base text-gray-600">Building apps that grow with your business, while keeping data safe.</p>
                                        </div>
                                   </div>
                              </div>

                              {/* Subheading and Description */}
                              <div className={`text-center md:text-left ${inView ? "animate-fadeInUp delay-200" : "opacity-0"}`}>
                                   <h2 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-4">Why Choose Us?</h2>
                                   <p className="text-base sm:text-lg text-gray-600 mb-6">
                                        At iJENTECH, we specialize in delivering cutting-edge mobile apps tailored to your business needs.
                                   </p>
                                   <ul className="space-y-4 text-left">
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> Customized, user-friendly apps
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> Robust app security
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> Cross-platform expertise
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> Optimized for performance and growth
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> **Cost-effective solutions** – Reduce development time and costs by using cross-platform frameworks.
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> **Continuous support and updates** – We provide ongoing support to ensure your app stays up-to-date.
                                        </li>
                                        <li className="flex items-start">
                                             <span className="bg-blue-500 text-white p-2 rounded-full mr-3">✓</span> **Advanced analytics and insights** – Track user behavior and app performance for data-driven improvements.
                                        </li>
                                   </ul>
                              </div>
                         </div>
                    </div>
               </section>
          </>
     );
};

export default MobileAppDevelopment;



