import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";


const CustomerCarousel = () => {
     const [currentIndex, setCurrentIndex] = useState(0);

     const customers = [
          { id: 1, src: "./images/webleo.jpg", alt: "Customer 1" },
          { id: 2, src: "https://newstar.ijentech.com/logo.png", alt: "newstar" },
          {id: 3, src: "https://res.umis.in/MANUFACTURER_26/LOGO/SAVE_20200628_201216202012215655202104131355.jpg"},
          // { id: 1, src: "./images/webleo.jpg", alt: "Customer 1" },
          // { id: 1, src: "./images/webleo.jpg", alt: "Customer 1" },
     ];

     const prevSlide = () => {
          setCurrentIndex((prevIndex) =>
               prevIndex === 0 ? customers.length - 1 : prevIndex - 1
          );
     };

     const nextSlide = () => {
          setCurrentIndex((prevIndex) =>
               prevIndex === customers.length - 1 ? 0 : prevIndex + 1
          );
     };

     return (
          <div className="flex flex-col items-center bg-gray-100 sm:px-20 px-10 py-6">
               <h2 className="text-2xl font-semibold mb-4 bg-white p-2 shadow-md rounded-md">Our Customers</h2>
               <div className="relative flex items-center w-full">
                    <button
                         onClick={prevSlide}
                         className="absolute left-0 text-xl p-2 bg-white rounded-full shadow-md z-10"
                    >
                         <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    {/* Logos */}
                    <div className="flex justify-center space-x-4 overflow-hidden w-full">
                         {customers.slice(currentIndex, currentIndex + 5).map((customer) => (
                              <img
                                   key={customer.id}
                                   src={customer.src}
                                   alt={customer.alt}
                                   className="h-16 mx-2 sm:mx-5 ml-10 w-auto object-contain"
                              />
                         ))}
                    </div>

                    {/* Right Arrow */}
                    <button
                         onClick={nextSlide}
                         className="absolute right-2 sm:right-0 text-xl p-2 bg-white rounded-full shadow-md z-10"
                    >
                         <FontAwesomeIcon icon={faChevronRight}  />
                    </button>
               </div>
          </div>
     );
};

export default CustomerCarousel;
