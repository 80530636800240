import React from 'react';

const PrivacyPolicy = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="https://static.vecteezy.com/system/resources/thumbnails/024/162/442/small_2x/businessman-checking-the-steps-through-document-with-a-list-of-checkboxes-regulation-concepts-of-practices-and-policies-procedure-company-articles-of-association-terms-and-conditions-inspection-free-photo.jpg"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">

                              </h1>
                         </div>
                    </div>
               </div>

               <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-3xl mx-auto text-center">
                         <h1 className="text-4xl font-bold text-blue-900 mb-4">Privacy Policy</h1>
                         <p className="text-gray-600 mb-8">
                              This Privacy Policy outlines how iJENTECH collects, uses, and protects your personal information when you use our services.
                         </p>

                         <div className="text-left">
                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">1. Information Collection</h2>
                              <p className="text-gray-600 mb-6">
                                   We collect personal information that you provide to us, such as your name, email address, and any other details required for using our services. We also collect information about your usage of our services.
                              </p>

                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">2. Use of Information</h2>
                              <p className="text-gray-600 mb-6">
                                   iJENTECH uses the information collected to provide and improve our services, communicate with you, and ensure the security of our platform. We do not share your information with third parties without your consent, except as required by law.
                              </p>

                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">3. Data Security</h2>
                              <p className="text-gray-600 mb-6">
                                   We implement industry-standard security measures to protect your data. However, no method of transmission over the Internet is 100% secure, and we cannot guarantee absolute security.
                              </p>

                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">4. Your Rights</h2>
                              <p className="text-gray-600 mb-6">
                                   You have the right to access, update, or delete your personal information at any time by contacting us at privacy@ijenttech.com. We will respond to your requests in accordance with applicable data protection laws.
                              </p>

                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">5. Changes to this Policy</h2>
                              <p className="text-gray-600 mb-6">
                                   iJENTECH reserves the right to modify this Privacy Policy at any time. Changes will be posted on this page, and we encourage you to review it periodically.
                              </p>

                              <h2 className="text-2xl font-semibold text-gray-800 mb-4">6. Contact Us</h2>
                              <p className="text-gray-600">
                                   If you have any questions or concerns about our Privacy Policy, please contact us at privacy@ijenttech.com.
                              </p>
                         </div>
                    </div>
               </div>
          </>
     );
};

export default PrivacyPolicy;
