import React from 'react';

const Career = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="https://www.templeton-recruitment.com/hubfs/tech-sales-career-image.jpg"
                         alt="Background"
                         className="w-full h-full object-fill"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">
                                   We Want You. Apply And {' '}
                                   <span className="underline decoration-red-500">
                                        Work With Us
                                   </span>
                              </h1>
                         </div>
                    </div>
               </div>


               <div className="bg-gray-50 flex flex-col items-center justify-center py-16 px-4">
                    <div className="max-w-4xl mx-auto flex flex-col md:flex-row items-center justify-between">
                         <div className="flex-1">
                              <h1 className="text-3xl font-bold text-blue-900 mb-4">We Are Hiring! Are You the One?</h1>
                              <p className="text-gray-700 mb-4">
                                   As a software solution provider, we aim to provide intelligent technologies that can assist organizations
                                   in optimizing their work processes to increase production & revenue.
                              </p>
                              <p className="text-gray-700 mb-6">
                                   Are you a dynamic professional eager to demonstrate your abilities in assisting us and meeting our business
                                   objectives?
                              </p>
                              <p className="font-bold text-gray-900 mb-8">Join us right now!</p>
                              <a
                                   href="mailto:hr.support@ijentech.com"
                                   className="inline-block bg-purple-700 text-white py-3 px-6 rounded-lg hover:bg-purple-800 transition duration-300"
                              >
                                   Send Your Resume
                              </a>
                         </div>
                         <div className="flex-1 mt-8 md:mt-0 relative ">
                              {/* Placeholder for images or illustration */}
                              <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                                   <img
                                        src="./images/career-sub-img.jpg"
                                        alt="Illustration"
                                        className="object-contain w-96 h-96"
                                   />
                              </div>
                         </div>
                    </div>
               </div>

               <div className="bg-blue-50 py-16 px-4">
                    <div className="max-w-7xl mx-auto text-center">
                         <h2 className="text-4xl font-bold text-gray-900 mb-8">Reasons To Work With Us</h2>
                         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                              {/* Card 1 */}
                              <div className="bg-white rounded-lg shadow-md p-6">
                                   <div className="text-center">
                                        {/* Icon Placeholder */}
                                        <div className="text-indigo-600 mb-4 flex flex-col items-center">
                                             {/* User SVG Icon (Above the stars) */}
                                             <svg className="w-12 h-12" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M16 16v-1a4 4 0 00-8 0v1m8-8a4 4 0 11-8 0 4 4 0 018 0z"></path>
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 14c-2.21 0-4 1.79-4 4v4h8v-4c0-2.21-1.79-4-4-4z"></path>
                                             </svg>

                                             {/* Star Rating SVG Icons (Below the user image) */}
                                             <div className="flex mt-2">
                                                  {/* Five stars for the rating */}
                                                  {[...Array(5)].map((_, index) => (
                                                       <svg key={index} className="w-6 h-6 mx-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.286 3.958a1 1 0 00.95.69h4.208c.969 0 1.371 1.24.588 1.81l-3.408 2.474a1 1 0 00-.364 1.118l1.286 3.958c.3.921-.755 1.688-1.539 1.118l-3.408-2.474a1 1 0 00-1.176 0l-3.408 2.474c-.784.57-1.839-.197-1.539-1.118l1.286-3.958a1 1 0 00-.364-1.118L2.75 9.385c-.784-.57-.38-1.81.588-1.81h4.208a1 1 0 00.95-.69L9.05 2.927z"></path>
                                                       </svg>
                                                  ))}
                                             </div>
                                        </div>

                                        <h3 className="text-xl font-bold text-gray-900 mb-4">Our Portfolio Is a Good Example of Our Success</h3>
                                        <p className="text-gray-600">
                                             Any business solution provider's portfolio is recognized by its historical performance. It conveys a clear picture of the company’s reliability and authenticity.
                                        </p>
                                   </div>
                              </div>

                              {/* Card 2 */}
                              <div className="bg-white rounded-lg shadow-md p-6">
                                   <div className="text-center">
                                        {/* Icon Placeholder */}
                                        <div className="text-indigo-600 mb-4 flex flex-col items-center">
                                             {/* Team Building and Workplace Culture SVG: 4 Users Connected */}
                                             <svg className="w-24 h-24" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                                                  {/* Center Circle (Connection Hub) */}
                                                  <circle cx="24" cy="24" r="3" fill="currentColor"></circle>

                                                  {/* Top User */}
                                                  <circle cx="24" cy="6" r="4" stroke="currentColor" strokeWidth="2"></circle>
                                                  <path d="M24 9 L24 21" stroke="currentColor" strokeWidth="2"></path>

                                                  {/* Bottom User */}
                                                  <circle cx="24" cy="42" r="4" stroke="currentColor" strokeWidth="2"></circle>
                                                  <path d="M24 39 L24 27" stroke="currentColor" strokeWidth="2"></path>

                                                  {/* Left User */}
                                                  <circle cx="6" cy="24" r="4" stroke="currentColor" strokeWidth="2"></circle>
                                                  <path d="M9 24 L21 24" stroke="currentColor" strokeWidth="2"></path>

                                                  {/* Right User */}
                                                  <circle cx="42" cy="24" r="4" stroke="currentColor" strokeWidth="2"></circle>
                                                  <path d="M39 24 L27 24" stroke="currentColor" strokeWidth="2"></path>
                                             </svg>
                                        </div>
                                        <h3 className="text-xl font-bold text-gray-900 mb-4">Team Building and Workplace Culture</h3>
                                        <p className="text-gray-600">
                                             We believe in teamwork that results in client success. Our team culture has always been employee-friendly and adaptive in order to sustain 100% productivity.
                                        </p>
                                   </div>
                              </div>

                              {/* Card 3 */}
                              <div className="bg-white rounded-lg shadow-md p-6">
                                   <div className="text-center">
                                        {/* Icon Placeholder */}
                                        <div className="text-indigo-600 mb-4">
                                             <svg className="w-12 h-12 mx-auto" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 2l2 7h7l-6 4 2 7-6-4-6 4 2-7-6-4h7l2-7z"></path>
                                             </svg>
                                        </div>
                                        <h3 className="text-xl font-bold text-gray-900 mb-4">Taking Actions That Matter</h3>
                                        <p className="text-gray-600">
                                             We overcome obstacles because we believe that what we do matters. Our work can be difficult, but it’s also satisfying, and it suits people who are adaptable and welcome change.
                                        </p>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>

               <div className="bg-gradient-to-r from-white to-gray-100 py-16 px-8 text-center">
                    <h1 className="text-3xl md:text-5xl font-bold text-indigo-900 mb-4">
                         Build Your Future; Build Your Dream.
                    </h1>
                    <p className="text-gray-700 text-lg mb-4">
                         You will work in a safe and secure environment where you will be encouraged to engage, contribute, exchange ideas, and grow in order to attain your full potential.
                    </p>
                    <p className="text-gray-600 text-sm mb-8">
                         If there are no current positions, please send us your resume to <a href="mailto:hr.support@ijentech.com" className="text-indigo-700 underline">hr.support@ijentech.com</a> for future consideration if your abilities fit our needs.
                    </p>
                    <a href="mailto:hr.support@ijentech.com" className="bg-indigo-800 text-white px-6 py-3 rounded-md text-lg font-semibold shadow-lg hover:bg-indigo-900 focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-opacity-50">
                         Browse Current Openings &rarr;
                    </a>
               </div>
          </>
     );
};

export default Career;
