import React from "react";
import HomeCard from "./HomeCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import 'swiper/css';
import 'swiper/css/navigation';
import "swiper/css/effect-coverflow";  // For coverflow effect
import "swiper/css/parallax"; // Import parallax effect CSS
import { Navigation, Autoplay, EffectCoverflow, Parallax, EffectFade } from 'swiper/modules'; // Add more effects
import { useNavigate } from "react-router-dom";
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import TechStack from "./Techstack";
import CustomerCarousel from "./Coustomer";
import ReviewCards from "./ReviewCards";
// import Testimonials from "./Testimonials";

const slides = [
     {
          image: "https://portal.apexbrasil.com.br/wp-content/uploads/2020/07/90615677-l.jpg",
          title: "AgriTech Revolution: Growing with Innovation and Technology",
          text: "Transforming ideas into reality.",
          link: "/agericulter-in-technology"
     },
     {
          image: "./images/ml.jpg",
          title: "",
          text: "",
          link: "/machine-learning"
     },
     {
          image: "./images/robotic.jpg",
          title: "Robots That Think. Solutions That Deliver.",
          text: "In the world of smart robotics, no task is too complex, no challenge too great.",
          link: "/robotic-technology"
     },
     {
          image: "https://benchstep.com/erp/assets/img/blog/erp.jpeg",
          title: "Streamline Your Business with Our Cutting-Edge ERP Solutions",
          text: "Enterprise resource planning (ERP) refers to a type of software that organizations use to manage day-to-day business activities such as accounting, procurement, project management, risk management and compliance, and supply chain operations......",
          link: "/erp-in-technology"
     },
     {
          image: "./images/blockchain.jpeg",
          title: "Transforming Business through Transparency and Trust",
          text: "Blockchain doesn’t just add technology; it adds trust. Imagine a network where every transaction is transparent, every process automated, and every stakeholder accountable. That’s the future we help you build.",
          link: "/blockchain-technology"
     },
];




const events = [
     {
          id: 1,
          title: "Tech Revolution",
          description: "Join us for the ultimate tech showdown! Innovators, disruptors, and dreamers unite!",
          image: "https://r.mobirisesite.com/746727/assets/images/photo-1421757350652-9f65a35effc7.jpeg", // Replace with your image path or URL
     },
     {
          id: 2,
          title: "AI & You: The Future Unveiled",
          description: "Discover how AI is reshaping our world. Don’t miss the insights from industry leaders!",
          image: "https://r.mobirisesite.com/746727/assets/images/photo-1487887235947-a955ef187fcc.jpeg",
     },
     {
          id: 3,
          title: "Cybersecurity",
          description: "Protect your digital assets! Learn from the best in the biz at this must-attend event!",
          image: "https://r.mobirisesite.com/746727/assets/images/photo-1473091534298-04dcbce3278c.jpeg",
     },
     {
          id: 4,
          title: "Innovators of Tech Programs",
          description: "Celebrate the brightest minds in tech! Join us for out Tech programs of inspiration and innovation!",
          image: "https://r.mobirisesite.com/746727/assets/images/photo-1498050108023-c5249f4df085.jpeg",
     },
];

// const TypewriterEffect = ({ text }) => {
//      const [displayedText, setDisplayedText] = useState("");
//      const [index, setIndex] = useState(0);

//      useEffect(() => {
//           const interval = setInterval(() => {
//                if (index < text.length) {
//                     setDisplayedText((prev) => prev + text.charAt(index));
//                     setIndex((prev) => prev + 1);
//                } else {
//                     clearInterval(interval); // Stop the interval when the text is fully displayed
//                     setTimeout(() => {
//                          setDisplayedText(""); // Clear the displayed text
//                          setIndex(0); // Reset index to start typing again
//                     }, 1000); // Optional delay before restarting (1 second)
//                }
//           }, 300); // Set the interval time (300ms for each character)

//           return () => clearInterval(interval); // Cleanup the interval on component unmount
//      }, [index, text]);

//      return <>{displayedText}</>;
// };

const Home = () => {

     // const [randomSlide, setRandomSlide] = useState(0);

     // useEffect(() => {
     //      setRandomSlide(Math.floor(Math.random() * images.length));
     // }, []);
     const navigate = useNavigate();

     const handleClick = () => {
          navigate("/about"); // Replace this with the target route
     };

     // super read more click
     const handleReadMore = (link) => {
          window.location.href = link; // For basic React routing
     };
     const ref = useRef(null);
     const isInView = useInView(ref, { once: false });
     const sideRef = useRef(null);
     const sideView = useInView(sideRef, { once: false });
     return (
          <>
               {/* update super */}
               <div className="relative">
                    <Swiper
                         navigation={{
                              nextEl: '.swiper-button-next',
                              prevEl: '.swiper-button-prev',
                              hideOnClick: true,
                         }}
                         autoplay={{ delay: 4000, disableOnInteraction: false }}
                         speed={800}
                         loop={true}
                         effect="EffectFade"
                         parallax={true}
                         modules={[Navigation, Autoplay, EffectCoverflow, Parallax, EffectFade]}
                         className="mySwiper relative"
                    >
                         {slides.map((slide, index) => (
                              <SwiperSlide key={index}>
                                   <div className="relative w-full h-screen">
                                        <img
                                             src={slide.image}
                                             alt={`Slide ${index}`}
                                             className="w-full h-screen object-fill"
                                        />
                                        <div className="absolute inset-0 flex flex-col justify-center items-center md:items-start md:pl-28 bg-black bg-opacity-50 text-white p-4 z-10">
                                             <h1 className="text-3xl md:text-4xl font-bold mb-4 font-fredoka">
                                                  {slide.title}
                                             </h1>
                                             <p className="text-base md:text-lg max-w-xl text-center md:text-left font-openSans mb-4">
                                                  {slide.text}
                                             </p>
                                             <button
                                                  onClick={() => handleReadMore(slide.link)}
                                                  className="text-white bg-transparent border border-white py-2 px-4 rounded-full hover:bg-white hover:bg-opacity-20 transition"
                                             >
                                                  <FontAwesomeIcon icon={faChevronRight} />
                                             </button>
                                        </div>
                                   </div>
                              </SwiperSlide>
                         ))}
                    </Swiper>

                    {/* Navigation buttons */}
                    <div className="swiper-button-prev sm:hidden"></div>
                    <div className="swiper-button-next sm:hidden"></div>
               </div>

               {/* end super */}
               <div
                    className="bg-gray-100 py-16 flex items-center justify-center bg-cover bg-center"
                    style={{ backgroundImage: "url('https://www.csm.tech/public/img/website/world-map.png')" }} // Replace 'your-image-url.jpg' with the actual image URL
               >
                    <div className="w-full h-full flex items-center justify-center">
                         <motion.section
                              className="text-center py-16 text-black"
                              ref={ref}
                              initial={{ opacity: 0, scale: 0.8 }} // Start smaller (scale: 0.8)
                              animate={isInView ? { opacity: 1, scale: 1 } : { opacity: 0, scale: 0.8 }} // Scale to original size (scale: 1)
                              transition={{ opacity: { duration: 1 }, scale: { duration: 1.2 } }} // Duration of the animation
                         >
                              <h2 className="text-4xl font-bold mb-4">About us</h2>
                              <p className="text-lg mb-12">
                                   A global leader in next-generation digital services and consulting
                              </p>
                              {/* Responsive layout */}
                              <div className="flex flex-col md:flex-row justify-center space-y-12 md:space-y-0 md:space-x-16">
                                   <div className="text-center">
                                        <span className="block text-5xl font-bold">56</span>
                                        <p className="mt-2">countries where we have trusting clients</p>
                                   </div>
                                   {/* <div className="text-center">
                                        <span className="block text-5xl font-bold">US$ 18.7</span>
                                        <p className="mt-2">billion total revenue (LTM)</p>
                                   </div> */}
                                   <div className="text-center">
                                        <span className="block text-5xl font-bold">24+</span>
                                        <p className="mt-2">million training (hours) conducted for employees, globally</p>
                                   </div>
                              </div>
                              <motion.button
                                   className="mt-12 px-8 py-4 bg-gradient-to-r from-blue-500 via-blue-500 to-blue-500 text-white rounded-lg shadow-md focus:outline-none"
                                   onClick={handleClick}
                                   whileHover={{
                                        background: "linear-gradient(to right, #3B82F6 0%, #1E40AF 100%)",
                                        transition: { duration: 0.5, ease: "easeInOut" },
                                   }}
                                   animate={{
                                        background: "linear-gradient(to right, #3B82F6 50%, #1E40AF 50%)",
                                        transition: { duration: 0.5, ease: "easeInOut" },
                                   }}
                                   whileTap={{ scale: 0.95 }}
                              >
                                   More Info <FontAwesomeIcon icon={faCircleArrowRight} className="ml-2" />
                              </motion.button>
                         </motion.section>
                    </div>
               </div>

               <div className="bg-gray-100 py-16">
                    <div className="container mx-auto px-4 lg:flex lg:items-center lg:justify-between">
                         {/* Left Side: Text Content */}
                         <motion.div
                              className="lg:w-1/2"
                              ref={sideRef}
                              initial={{ opacity: 0, x: -100 }} // Start from left (-100px)
                              animate={sideView ? { opacity: 1, x: 0 } : {}} // Animate to original position
                              transition={{ duration: 2 }} // 1-second duration
                         >
                              <h1 className="text-5xl font-bold text-gray-800 mb-6">
                                   <span className="text-yellow-500">Top Professionals</span> for Turning Your Business Idea into Custom Software Solutions
                              </h1>
                              <p className="text-lg text-gray-600 mb-8">
                                   We are iJENTECH Technologies, a pioneering digital product and software development company with a team of 150+ expert developers, QA engineers, project managers, and business experts. With 13+ years of expertise and a track record of delivering 4000+ projects, we ensure the right results on time and within budget.
                              </p>
                              <button className="bg-gradient-to-r from-blue-500 to-teal-400 text-white py-3 px-6 rounded-lg shadow-lg transition duration-300 ease-in-out">
                                   Explore More
                              </button>
                         </motion.div>

                         {/* Right Side: Stats Boxes */}
                         <div className="lg:w-1/2 mt-12 lg:mt-0 grid grid-cols-1 sm:grid-cols-2 gap-8">
                              {/* First Box: Slightly shifted upwards */}
                              <div className="bg-white p-6 border-b-4 border-yellow-500 rounded-lg shadow-lg transform -translate-y-7 hover:scale-105 transition duration-500 ease-in-out">
                                   <h2 className="text-5xl font-extrabold text-gray-800">13+</h2>
                                   <p className="mt-2 text-gray-700">Years in Business</p>
                                   <p className="text-gray-500 mt-2">We boast more than a decade of experience in the software development industry.</p>
                              </div>

                              {/* Second Box: Normal position */}
                              <div className="bg-white mb-10 p-6 border-b-4 border-blue-500 rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out">
                                   <h2 className="text-5xl font-extrabold text-gray-800">150+</h2>
                                   <p className="mt-2 text-gray-700">Full-Time Experts</p>
                                   <p className="text-gray-500 mt-2">We have highly experienced & skilled professionals to build your product.</p>
                              </div>

                              {/* Third Box: Slightly shifted upwards */}
                              <div className="bg-white p-6 border-b-4 border-yellow-500 rounded-lg shadow-lg transform -translate-y-7 hover:scale-105 transition duration-500 ease-in-out">
                                   <h2 className="text-5xl font-extrabold text-gray-800">98%</h2>
                                   <p className="mt-2 text-gray-700">Projects Delivered On-Time</p>
                                   <p className="text-gray-500 mt-2">All our projects are delivered on time while maintaining a commitment to excellence in quality.</p>
                              </div>

                              {/* Fourth Box: Normal position */}
                              <div className="bg-white p-6 border-b-4 border-blue-500 rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out">
                                   <h2 className="text-5xl font-extrabold text-gray-800">93%</h2>
                                   <p className="mt-2 text-gray-700">Clients Return for Additional Projects</p>
                                   <p className="text-gray-500 mt-2">Impressive client retention rate, underscoring our commitment to client satisfaction.</p>
                              </div>
                         </div>
                    </div>
               </div>

               {/* <Testimonials /> */}

               <div className="bg-gray-100">
                    <div className="container mx-auto p-5 ">
                         <h1 className="text-3xl font-bold text-center mb-8">Explore Our Offerings</h1>
                         <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                              {events.map((event) => (
                                   <HomeCard key={event.id} event={event} />
                              ))}
                         </div>
                    </div>
               </div>

               <TechStack />
               <CustomerCarousel />
               <ReviewCards />
          </>
     );
};

export default Home;
