import React, { useEffect, useState } from 'react';

const CursorDot = () => {
     const [position, setPosition] = useState({ x: 0, y: 0 });

     useEffect(() => {
          const handleMouseMove = (e) => {
               const mouseX = e.clientX;
               const mouseY = e.clientY;

               // Directly set the cursor position
               setPosition({ x: mouseX, y: mouseY });
          };

          // Add mousemove event listener
          window.addEventListener('mousemove', handleMouseMove);

          // Clean up the event listener on component unmount
          return () => {
               window.removeEventListener('mousemove', handleMouseMove);
          };
     }, []);

     return (
          <div
               className="w-3 h-3 bg-red-500 rounded-full absolute pointer-events-none transition-transform duration-75 ease-in-out"
               style={{
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    top: 0,
                    left: 0,
               }}
          />
     );
};

export default CursorDot;
