import React, { useState } from 'react';


const ContactUs = () => {
     const [formData, setFormData] = useState({
          name: '',
          email: '',
          subject: '',
          message: '',
     });

     const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
     };

     const handleSubmit = (e) => {
          e.preventDefault();

          // For demonstration, log the data to the console
          console.log('Form Data:', formData);

          // Here you would send the data to your backend
          fetch('http://localhost:5000/api/send-email', {
               method: 'POST',
               headers: {
                    'Content-Type': 'application/json',
               },
               body: JSON.stringify(formData),
          })
               .then((response) => {
                    if (!response.ok) {
                         throw new Error('Network response was not ok ' + response.statusText);
                    }
                    return response.json();
               })
               .then((data) => {
                    console.log('Success:', data);
                    alert('Your message has been sent!');
               })
               .catch((error) => {
                    console.error('Error:', error);
                    alert('Failed to send message: ' + error.message);
               });

          // Reset form after submission
          setFormData({
               name: '',
               email: '',
               subject: '',
               message: '',
          });
     };

     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    <img
                         src="https://media.istockphoto.com/id/1311934969/photo/contact-us.jpg?s=612x612&w=0&k=20&c=_vmYyAX0aFi-sHH8eYS-tLLNfs1ZWXnNB8M7_KWwhgg="
                         alt="Background"
                         className="w-full h-full object-cover sm:object-fill"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-8">
                         <div className="text-left text-white">
                              <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-4 sm:mb-6 ml-4 sm:ml-8 sm:mr-32">
                                   <span className="underline decoration-red-500">We're here for you.</span>
                              </h1>
                         </div>
                    </div>
               </div>

               <div className="flex flex-col items-center justify-center min-h-screen bg-blue-50">
                    <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-md">
                         <h1 className="text-2xl font-bold text-center mb-6">Contact Us</h1>
                         <form onSubmit={handleSubmit} className="space-y-4">
                              <div>
                                   <input
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Name"
                                        required
                                   />
                              </div>
                              <div>
                                   <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Email"
                                        required
                                   />
                              </div>
                              <div>
                                   <input
                                        type="text"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Subject"
                                        required
                                   />
                              </div>
                              <div>
                                   <textarea
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                        className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                                        placeholder="Message"
                                        rows="4"
                                        required
                                   />
                              </div>
                              <button
                                   type="submit"
                                   className="w-full py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                              >
                                   Send Message
                              </button>
                         </form>
                    </div>
                    <div className="mt-8">
                         <img
                              src="../images/ijentech.png" // Add the illustration URL
                              alt="Contact Illustration"
                              className="w-32 h-10"
                         />
                    </div>
               </div>


               {/* Social Media Icons */}
               
          </>
     );
};

export default ContactUs;
