import React from "react";
import { Route, Routes } from "react-router-dom";
import Navbar from "../pages/navbar/Navbar";
import Home from "../pages/Home/Home";
import Footer from "../pages/footer/Footer"
import About from "../pages/About/About"
import Services from "./Services/Services";
import Webdevelopment from "./Services/webdevelopment";
import MobileAppDevelopment from "./Services/MobileAppDevelopment";
import IoTDevelopmentServices from "./Services/IoTDevelopmentServices";
import Digitalmarkiting from "./Services/Digitalmarketing";
import SEOPage from "./Services/SEOPage";
import OtherDevelopment from "./Services/OtherDevelopment";
import ContactUs from "./Contactus/Contactus";
import Career from "./Career/Career";
import TermsAndConditions from "./other/Terms_and_condition";
import PrivacyPolicy from "./other/PrivacyPolicy ";
import Machinelearning from "./Home/Machinelearning";
import Robotic from "./Home/Robotic";
import ERPArticle from "./Home/ERPArticle";
import Blockchain from "./Home/Blockchain";
import AgricultureTechArticle from "./Home/AgricultureTechArticle";
const Routing = () => {
     return (
          <>
               <Routes>
                    <Route exact path="/" element={<Navbar />}>
                         <Route index element={<Home />} />
                         <Route path="/machine-learning" element={<Machinelearning />} />
                         <Route path="/robotic-technology" element={<Robotic />} />
                         <Route path="/erp-in-technology" element={<ERPArticle />} />
                         <Route path="/blockchain-technology" element={<Blockchain />} />
                         <Route path="/agericulter-in-technology" element={<AgricultureTechArticle />} />
                         <Route path="/about" element={<About />} />
                         <Route path="/services" element={<Services />} />
                         <Route path="/services/web-development" element={<Webdevelopment />} />
                         <Route path="/services/mobileApp-development" element={<MobileAppDevelopment />} />
                         <Route path="/services/iot-development" element={<IoTDevelopmentServices />} />
                         <Route path="/services/seo-management" element={<SEOPage />} />
                         <Route path="/services/digital-marketing" element={<Digitalmarkiting />} />
                         <Route path="/services/other-development" element={<OtherDevelopment />} />
                         <Route path="/contact" element={<ContactUs />} />
                         <Route path="/career" element={<Career />} />
                         <Route path="/Terms-and-conditions" element={<TermsAndConditions />} />
                         <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    </Route>
               </Routes>
               <Footer />
          </>
     )
}
export default Routing;