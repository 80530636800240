import React, { useState, useEffect } from 'react';
import { NavLink, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faBars } from '@fortawesome/free-solid-svg-icons';
import CursorDot from '../CursorDot';

const Navbar = () => {
     const [isOpen, setIsOpen] = useState(false);
     const [scrollPosition, setScrollPosition] = useState(0);

     const toggleMenu = () => {
          setIsOpen(!isOpen);
     };

     // Function to handle scroll event
     const handleScroll = () => {
          const position = window.pageYOffset;
          setScrollPosition(position);
     };

     useEffect(() => {
          window.addEventListener('scroll', handleScroll);
          return () => {
               window.removeEventListener('scroll', handleScroll);
          };
     }, []);

     return (
          <>
               <div className='fixed w-full z-20'>
                    <nav className={`relative shadow-md ${scrollPosition > 50 ? 'bg-gray-900' : 'bg-transparent'} transition-all duration-300`}>
                         <CursorDot />
                         {/* Navbar Content */}
                         <div className="relative z-10 px-4 py-3">
                              <div className="container mx-auto flex justify-between items-center">
                                   {/* Logo Section */}
                                   <div className="flex items-center">
                                        <img src="../images/ijentech.png" alt="logo" className='w-40 h-14' />
                                   </div>

                                   {/* Desktop Menu */}
                                   <ul className="hidden md:flex space-x-6 items-center">
                                        <li>
                                             <NavLink to="/" className="text-white hover:text-green-400 transition-colors duration-200 text-xl font-poppins">Home</NavLink>
                                        </li>
                                        <li>
                                             <NavLink to="/about" className="text-white hover:text-green-400 transition-colors duration-200 text-xl font-poppins">About Us</NavLink>
                                        </li>
                                        <li>
                                             <NavLink to="/services" className="text-white hover:text-green-400 transition-colors duration-200 text-xl font-poppins">Services</NavLink>
                                        </li>
                                        <li>
                                             <NavLink to="/contact" className="text-white hover:text-green-400 transition-colors duration-200 text-xl font-poppins">Contact Us</NavLink>
                                        </li>
                                   </ul>

                                   {/* Call Section */}
                                   <div className="hidden md:flex items-center space-x-2 text-white">
                                        <div className="bg-blue-400 rounded-full p-2">
                                             <FontAwesomeIcon icon={faPhone} className="text-white" />
                                        </div>
                                        <span className='font-poppins'>Call Us Any Time</span>
                                        <span className='font-poppins'>(+91) 760 5953 454</span>
                                   </div>

                                   {/* Mobile Menu Button */}
                                   <div className="md:hidden">
                                        <button onClick={toggleMenu} className="text-white focus:outline-none">
                                             <FontAwesomeIcon icon={faBars} className="text-3xl" />
                                        </button>
                                   </div>
                              </div>
                         </div>
                    </nav>
                    {/* Mobile Menu */}
                    {isOpen && (
                         <div className="md:hidden bg-[#474646] bg-opacity-90 transition-transform transform duration-300 ease-in-out m-3 rounded-lg">
                              <ul className="space-y-4 mt-4 px-4 py-2">
                                   <li>
                                        <NavLink to="/" className="text-white block hover:text-green-400">Home</NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/about" className="text-white block hover:text-green-400">About Us</NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/services" className="text-white block hover:text-green-400">Services</NavLink>
                                   </li>
                                   <li>
                                        <NavLink to="/contact" className="text-white block hover:text-green-400">Contact Us</NavLink>
                                   </li>
                                   <li className="text-white">
                                        <div className="flex items-center space-x-2">
                                             <div className="bg-green-600 rounded-full p-2">
                                                  <FontAwesomeIcon icon={faPhone} className="text-white" />
                                             </div>
                                             <span>Call Us Any Time</span>
                                             <span>(+01) 789 987 645</span>
                                        </div>
                                   </li>
                              </ul>
                         </div>
                    )}
               </div>
               <Outlet />
          </>
     );
};

export default Navbar;
