// src/DevelopmentRoadmap.js
import React from 'react';
import { motion } from 'framer-motion';

const developmentData = [
     {
          title: 'UI/UX Development',
          description: 'Creating intuitive and engaging user experiences and interfaces.',
          date: 'Q1 2024',
          advantages: [
               'User-Centric Design',
               'Increased Engagement',
               'Enhanced Usability',
               'Brand Consistency',
          ],
     },
     {
          title: 'SaaS Development',
          description: 'Building scalable and robust Software as a Service applications.',
          date: 'Q2 2024',
          advantages: [
               'Subscription-Based Revenue',
               'Easy Scalability',
               'Regular Updates',
               'Access Anywhere',
          ],
     },
     {
          title: 'Cloud Development (AWS)',
          description: 'Leveraging AWS to build reliable, scalable, and cost-effective applications.',
          date: 'Q3 2024',
          advantages: [
               'High Availability',
               'Flexible Resource Management',
               'Security and Compliance',
               'Global Reach',
          ],
     },
];

const DevelopmentRoadmap = () => {
     return (
          <div className="py-12 bg-gray-100">
               <h2 className="text-3xl font-bold text-center mb-8">Our Developments</h2>
               <div className="flex flex-col items-center">
                    {developmentData.map((item, index) => (
                         <motion.div
                              key={index}
                              className="relative w-72 mb-8"
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{ duration: 0.5 }}
                         >
                              <div className="bg-white p-6 rounded-lg shadow-md">
                                   <h3 className="text-xl font-semibold">{item.title}</h3>
                                   <p className="text-gray-600 mb-2">{item.description}</p>
                                   <p className="text-gray-500 mb-4">{item.date}</p>
                                   <h4 className="font-semibold mb-2">Advantages:</h4>
                                   <ul className="list-disc list-inside text-gray-600">
                                        {item.advantages.map((advantage, idx) => (
                                             <li key={idx}>{advantage}</li>
                                        ))}
                                   </ul>
                              </div>
                              {index < developmentData.length - 1 && (
                                   <div className="absolute left-1/2 w-1 h-16 bg-gray-300 transform -translate-x-1/2"></div>
                              )}
                         </motion.div>
                    ))}
               </div>
          </div>
     );
};

export default DevelopmentRoadmap;
