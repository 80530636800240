import React from 'react';

const TermsAndConditions = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    {/* Background Image */}
                    <img
                         src="https://img.freepik.com/premium-vector/illustration-terms-conditions-concept-landing-page_108061-449.jpg"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />

                    {/* Overlay for Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">
                                   
                              </h1>
                         </div>
                    </div>
               </div>
               <div className="max-w-4xl mx-auto p-6">
                    <h1 className="text-3xl font-bold mb-6 text-blue-900">Terms and Conditions</h1>
                    <h2 className="text-2xl font-semibold mt-4 mb-2">1. Introduction</h2>
                    <p className="mb-4">
                         Welcome to iJENTECH! These Terms and Conditions outline the rules and regulations for the use of our services. By accessing or using our services, you agree to comply with these terms. If you do not agree, please refrain from using our services.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">2. Definitions</h2>
                    <p className="mb-4">
                         For the purposes of these Terms, "Company", "we", "us", or "our" refers to iJENTECH. "Services" refers to all services offered by the Company.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">3. User Responsibilities</h2>
                    <p className="mb-4">
                         Users are responsible for maintaining the confidentiality of their account information. You agree to notify us immediately of any unauthorized use of your account.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">4. Intellectual Property</h2>
                    <p className="mb-4">
                         All content, trademarks, and other intellectual property on our website and services are owned by or licensed to iJENTECH. You may not use, reproduce, or distribute any of our intellectual property without our express written permission.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">5. Limitation of Liability</h2>
                    <p className="mb-4">
                         iJENTECH will not be liable for any damages arising from the use of or inability to use our services, even if we have been advised of the possibility of such damages.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">6. Changes to Terms</h2>
                    <p className="mb-4">
                         We reserve the right to modify these Terms at any time. Changes will be effective immediately upon posting on our website. It is your responsibility to review these Terms periodically.
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">7. Governing Law</h2>
                    <p className="mb-4">
                         These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from these Terms will be handled in the courts of [Your Jurisdiction].
                    </p>

                    <h2 className="text-2xl font-semibold mt-4 mb-2">8. Contact Us</h2>
                    <p className="mb-4">
                         If you have any questions about these Terms, please contact us at:
                         <br />
                         <strong>Email:</strong> hr.support@ijentech.com
                         <br />
                         <strong>Phone:</strong> 7605953454
                    </p>

                    <p className="mb-4">
                         Thank you for choosing iJENTECH!
                    </p>
               </div>
          </>
     );
};

export default TermsAndConditions;
