// src/App.js
import React from 'react';
import Roadmap from './otherdevroadmap';

const App = () => {
     return (
          <>
               <div className="relative h-screen overflow-hidden">
                    <img
                         src="https://leadx.org/wp-content/uploads/2019/03/shutterstock_637157317.jpg"
                         alt="Background"
                         className="w-full h-full object-cover sm:object-fill"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-8">
                         <div className="text-left text-white">
                              <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-4 sm:mb-6 ml-4 sm:ml-8 sm:mr-32">
                              Be Extraordinary, <span className="underline decoration-red-500">Be You .</span>
                              </h1>
                         </div>
                    </div>
               </div>
               <div className="min-h-screen bg-gray-100">
                    <div className="px-4">
                         <h1 className="text-4xl font-bold text-center py-8">Welcome to Our Tech Company</h1>
                         <p className="text-center text-gray-700 mb-6">
                              We are dedicated to building innovative solutions for the digital age.
                         </p>
                         <Roadmap />
                    </div>
               </div>
          </>
     );
};

export default App;

