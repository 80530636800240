import React from "react";
import { motion } from "framer-motion";

const Webdevelopment = () => {
     return (
          <>
               {/* Background Section */}
               <motion.div
                    className="relative h-screen overflow-hidden"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.5 }}
               >
                    {/* Background Image */}
                    <img
                         src="https://sun9-54.userapi.com/impg/K2_HzZHaFAgtho6DirfFqMVzlGSF9FdockUFrQ/TJaFuktYFcc.jpg?size=807x539&quality=96&sign=04604331b77a94ff434d128fde7d40cc&c_uniq_tag=E7HXd1eEg6BXe3SrJDTBOwi2i6SMPsHgRq551F-kafk&type=album"
                         alt="Background"
                         className="w-full h-full object-cover"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-8">
                         <div className="text-left text-white">
                              <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-6 ml-8 md:mr-80">
                                   Setting New Standards in <span> </span>
                                   <span className="underline decoration-red-500">
                                        Web Design
                                   </span>
                                   <span> </span>Excellence
                              </h1>
                         </div>
                    </div>
               </motion.div>

               {/* Open Source Development Section */}
               <motion.div
                    className="bg-gray-100 flex items-start justify-start px-10 py-5"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
               >
                    <div className="p-6 flex items-center justify-center">
                         <h2 className="text-xl font-bold mb-4 text-red-600">
                              Open Source Development
                         </h2>
                         <ul className="list-disc pl-5 space-y-2 text-gray-800">
                              {[
                                   { text: "PHP/LARAVEL Development", description: "We build beautiful & workable Laravel solutions to suit your needs." },
                                   { text: "WORDPRESS Development", description: "From blogs to theme designing & plugin development, we do it all." },
                                   { text: "IoT Development", description: "Build and manage smart, connected devices with data collection, real-time monitoring, and seamless integrations." },
                                   { text: "ReactJS Development", description: "We develop Feature-rich & Scalable Web & Mobile Apps using React-Native offering competitive edge over others." },
                                   { text: "Python Development", description: "We offer high-quality Python development services for web applications, automation, data analysis, and more." }
                              ].map((item, index) => (
                                   <motion.li
                                        key={index}
                                        initial={{ x: -50, opacity: 0 }}
                                        whileInView={{ x: 0, opacity: 1 }}
                                        transition={{ duration: 0.5, delay: index * 0.2 }}
                                        viewport={{ once: true }}
                                   >
                                        <strong>{item.text}</strong> {item.description}
                                   </motion.li>
                              ))}
                         </ul>
                    </div>
               </motion.div>

               {/* PHP Development Section */}
               <motion.div
                    className="bg-gray-100 rounded-lg shadow-lg px-10 py-5"
                    initial={{ opacity: 0, scale: 0.9 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
               >
                    <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">
                         PHP Development
                    </h1>
                    <p className="text-gray-600 mb-6">
                         PHP is a popular server-side scripting language that is widely used for web development. It is known for its flexibility, speed, and extensive community support, making it an ideal choice for building dynamic and interactive websites.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                         Benefits of PHP Development
                    </h2>
                    <ul className="list-disc list-inside text-gray-600">
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.1 }}>
                              Open-source and widely supported by a large community.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Great for building dynamic web pages and applications.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Supports a wide range of databases including MySQL, PostgreSQL, and more.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Highly flexible and scalable for projects of all sizes.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Integrates well with modern frameworks such as Laravel and Symfony.
                         </motion.li>

                    </ul>
               </motion.div>

               {/* WordPress Development Section */}
               <motion.div
                    className="bg-gray-100 px-10 py-5 rounded-lg shadow-lg"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
               >
                    <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">
                         WordPress Development
                    </h1>
                    <p className="text-gray-600 mb-6">
                         WordPress is the most popular content management system (CMS) used to create websites, blogs, and eCommerce stores. It offers a vast range of customization options, plugins, and themes to suit your business needs.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-700 mb-3">
                         Benefits of WordPress Development
                    </h2>
                    <ul className="list-disc list-inside text-gray-600">
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.1 }}>
                              Highly customizable with thousands of plugins and themes.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              SEO-friendly and easy to optimize for search engines.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Supports both blogging and professional website development.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Open-source platform with a large developer community.
                         </motion.li>
                         <motion.li initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ delay: 0.2 }}>
                              Easy to manage with a user-friendly admin dashboard.
                         </motion.li>

                    </ul>
               </motion.div>

               {/* IoT Development Section */}
               <motion.div
                    className="bg-gray-100 px-10 py-5 rounded-lg shadow-md"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6 }}
                    viewport={{ once: true }}
               >
                    <h1 className="text-4xl font-bold text-gray-800 mb-4 text-center">
                         IOT Development
                    </h1>
                    <ul className="list-disc pl-5 space-y-2 text-gray-800">
                         <li><strong>Smart Home Solutions</strong> We develop integrated solutions for smart homes, including automation and security systems.</li>
                         <li>
                              <strong>Industrial IoT</strong> Create robust, scalable platforms for industrial monitoring, predictive maintenance, and automation.
                         </li>
                         <li>
                              <strong>Wearable Devices Development</strong> Develop custom wearable IoT devices with real-time data collection, analysis, and cloud connectivity.
                         </li>
                         <li>
                              <strong>Healthcare IoT Solutions</strong> IoT-powered healthcare applications for remote patient monitoring, diagnostics, and health data analysis.
                         </li>
                         <li>
                              <strong>IoT Data Analytics</strong> Build data pipelines for IoT devices to collect, store, and analyze massive datasets for predictive insights.
                         </li>
                         <li>
                              <strong>IoT Security Solutions</strong> End-to-end security solutions, ensuring device and data protection for connected systems.
                         </li>

                    </ul>
               </motion.div>
          </>
     );
};

export default Webdevelopment;
