import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';


const Footer = () => {
     return (
          <>
               <footer className="bg-gray-900 text-white py-4">
                    <div className="text-white py-8">
                         <div className="container mx-auto px-4">
                              <div className="grid grid-cols-1 md:grid-cols-4 gap-8">

                                   {/* Company Section */}
                                   <div>
                                        <img src="/images/ijentech.png" alt="logo" className='w-36 h-14 py-1' />
                                        <p className="text-gray-400">
                                             iJENTECH is a technical partner for your software development and digital transformation.
                                             We strive for quality, cost-efficiency, innovation, and transparent partnership.
                                        </p>

                                        <div className="flex space-x-6 py-4">
                                             <a
                                                  href="https://www.facebook.com/ijentech"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="text-blue-600 hover:text-blue-800 transition duration-300 transform hover:scale-110"
                                             >
                                                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                                             </a>
                                             <a
                                                  href="https://www.twitter.com"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="text-blue-400 hover:text-blue-600 transition duration-300 transform hover:scale-110"
                                             >
                                                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                                             </a>
                                             <a
                                                  href="https://www.linkedin.com/company/ijentechtechnology/?viewAsMember=true"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="text-blue-700 hover:text-blue-900 transition duration-300 transform hover:scale-110"
                                             >
                                                  <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                             </a>
                                             <a
                                                  href="https://www.instagram.com/ijentech_technology/"
                                                  target="_blank"
                                                  rel="noopener noreferrer"
                                                  className="text-pink-500 hover:text-pink-700 transition duration-300 transform hover:scale-110"
                                             >
                                                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                                             </a>
                                        </div>
                                   </div>

                                   {/* Company Links */}
                                   <div>
                                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-red-600 inline-block">Company</h3>
                                        <ul className="text-gray-400 space-y-2">
                                             <li><Link to="/about" className="hover:text-white">About Us </Link></li>
                                             <li><a href="/services" className="hover:text-white">Services</a></li>
                                             <li><a href="#" className="hover:text-white">Team</a></li>
                                             <li><a href="/career" className="hover:text-white">Career</a></li>
                                             <li><a href="/contact" className="hover:text-white">Contact Us</a></li>
                                        </ul>
                                   </div>

                                   {/* Services Links */}
                                   <div>
                                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-red-600 inline-block">Services</h3>
                                        <ul className="text-gray-400 space-y-2">
                                             <li><a href="/services/web-development" className="hover:text-white">Web Development</a></li>
                                             <li><a href="/services/mobileApp-development" className="hover:text-white">Mobile App Development</a></li>
                                             <li><a href="#" className="hover:text-white">UX/UI Design</a></li>
                                             <li><a href="/services/other-development" className="hover:text-white">DevOps</a></li>
                                             <li><a href="/privacy-policy" className="hover:text-white">Privacy and Policy</a></li>
                                             <li><a href="/Terms-and-conditions" className="hover:text-white">Terms and Conditions</a></li>
                                             <li><a href="#" className="hover:text-white">Refund and Policy</a></li>
                                        </ul>
                                   </div>

                                   {/* Industries & Blog Links */}
                                   <div>
                                        <h3 className="text-lg font-semibold mb-4 border-b-2 border-red-600 inline-block">Industries</h3>
                                        <ul className="text-gray-400 space-y-2">
                                             <li><a href="#" className="hover:text-white">Finance & Banking</a></li>
                                             <li><a href="#" className="hover:text-white">Travel & Booking</a></li>
                                             <li><a href="#" className="hover:text-white">Taxi & Transportation</a></li>
                                             <li><a href="#" className="hover:text-white">Health & Fitness</a></li>
                                             <li><a href="#" className="hover:text-white">Real Estate</a></li>
                                             <li><a href="#" className="hover:text-white">Software</a></li>
                                             <li><a href="#" className="hover:text-white">E-commerce</a></li>
                                             <li><a href="#" className="hover:text-white">E-Governance</a></li>
                                        </ul>
                                   </div>

                              </div>
                         </div>
                    </div>
                    {/* Divider Line */}
                    <div className="border-t border-gray-600 mb-4 opacity-50"></div>

                    {/* Footer Content */}
                    <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center">
                         {/* Left Side - Copyright */}
                         <div className="text-sm mb-2 md:mb-0">
                              © iJENTECH. All Rights Reserved.
                         </div>

                         {/* Right Side - Links */}
                         <div className="flex space-x-4">
                              <a href="#" className="hover:underline">
                                   Privacy Policy
                              </a>
                              <a href="/Terms-and-conditions" className="hover:underline">
                                   Terms & Services
                              </a>
                         </div>
                    </div>
               </footer>

          </>

     );
};

export default Footer;
