import React from 'react';

const Digitalmarkiting = () => {
     return (
          <>
               {/* Hero Section */}
               <div className="relative h-screen overflow-hidden">
                    <img
                         src="https://www.webhopers.com/wp-content/uploads/2024/05/Top-10-Digital-Marketing-Companies-in-the-World-22.png"
                         alt="Background"
                         className="w-full h-full object-cover sm:object-fill"
                    />
                    {/* Overlay Text */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4 sm:px-8">
                         <div className="text-left text-white">
                              <h1 className="text-2xl sm:text-4xl md:text-6xl lg:text-6xl font-extrabold mb-4 sm:mb-6 ml-4 sm:ml-8 sm:mr-32">
                              Marketing Beyond <span className="underline decoration-red-500">Boundaries </span> .
                              </h1>
                         </div>
                    </div>
               </div>
               <div className="font-lato bg-gray-50 text-gray-800">
                    {/* Hero Section */}
                    <section className=" text-black p-8 md:p-16">
                         <div className="container mx-auto text-center">
                              <h1 className="text-4xl md:text-5xl font-bold mb-4">Digital Marketing Services</h1>
                              <p className="text-lg md:text-xl mb-6">
                                   Grow your business online with our innovative digital marketing solutions.
                              </p>
                         </div>
                    </section>

                    {/* Services Section */}
                    <section className="container mx-auto my-12 px-8">
                         <h2 className="text-3xl font-bold text-center mb-10">Our Services</h2>
                         <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                              {/* Service 1 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">SEO Optimization</h3>
                                   <p className="mb-4">
                                        Improve your website’s visibility on search engines with our expert SEO services.
                                   </p>
                              </div>
                              {/* Service 2 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">Content Marketing</h3>
                                   <p className="mb-4">
                                        Engage your audience with high-quality, targeted content tailored to your brand.
                                   </p>
                              </div>
                              {/* Service 3 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">Social Media Management</h3>
                                   <p className="mb-4">
                                        Boost your brand’s presence and engagement across social media platforms.
                                   </p>
                              </div>
                              {/* Service 4 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">Pay-Per-Click Advertising</h3>
                                   <p className="mb-4">
                                        Drive targeted traffic and generate leads with our PPC advertising strategies.
                                   </p>
                              </div>
                              {/* Service 5 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">Email Marketing</h3>
                                   <p className="mb-4">
                                        Engage and nurture your leads with effective email marketing campaigns.
                                   </p>
                              </div>
                              {/* Service 6 */}
                              <div className="p-6 bg-white shadow-md rounded-lg hover:shadow-lg transition">
                                   <h3 className="text-2xl font-semibold mb-3">Analytics & Reporting</h3>
                                   <p className="mb-4">
                                        Get in-depth insights and analytics to track the success of your campaigns.
                                   </p>
                              </div>
                         </div>
                    </section>

                    {/* Why Choose Us Section */}
                    <section className="py-12">
                         <div className="container mx-auto text-center px-8">
                              <h2 className="text-3xl font-bold mb-6">Why Choose Us?</h2>
                              <p className="text-lg mb-10">
                                   We are dedicated to helping businesses grow by offering tailored digital marketing
                                   strategies that bring results.
                              </p>
                              <div className="grid gap-8 md:grid-cols-3">
                                   <div>
                                        <h3 className="text-xl font-semibold mb-2">Experienced Team</h3>
                                        <p>Our team of experts has years of experience in digital marketing.</p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold mb-2">Proven Strategies</h3>
                                        <p>We employ data-driven strategies tailored to your business goals.</p>
                                   </div>
                                   <div>
                                        <h3 className="text-xl font-semibold mb-2">Transparent Reporting</h3>
                                        <p>We provide clear, detailed reports on your campaign’s performance.</p>
                                   </div>
                              </div>
                         </div>
                    </section>
               </div>
          </>
     );
};

export default Digitalmarkiting;